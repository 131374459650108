.base-theme {
    /* General colors */
    --color-gray-50: #F9FAFB;
    --color-gray-100: #F3F4F6;
    --color-gray-200: #ebebeb;
    --color-gray-300: #e0e0e0;
    --color-gray-400: #9CA3AF;
    --color-gray-500: #6B7280;
    --color-gray-600: #4B5563;
    --color-gray-700: #374151;
    --color-gray-800: #1F2937;
    --color-gray-900: #111827;

    --color-inverse: #fff;
    --color-popup: #fefefe;

    /* Font */
    --font-display: 'Nunito', sans-serif;
    --font-body: 'Nunito', sans-serif;

    --font-weight-normal: 400;
    --font-weight-display: 600;
    --font-weight-btn: 600;

    --rounded-btn: 0.375rem;
    --rounded-card: 0.375rem;
    --rounded-input: 0.375rem;

    --btn-height: 2.25rem;

    --color-field: #ffffff;
    --color-primary-light: #184f90;

    --color-primary: #0075e0;
    --color-primary-shade: #0067c5;
    --color-primary-tint: #1a83e3;
    --color-primary-transparent: rgba(0, 117, 224, 0.05);
    --color-primary-contrast: var(--color-gray-50);

    --color-secondary: #e05200;
    --color-secondary-shade: #c54800;
    --color-secondary-tint: #e3631a;

    /* React-select colors */
    --ring-primary: var(--color-primary) 0px 0px 0px 0px, var(--color-primary) 0px 0px 0px 2px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;

    --color-tooltip-text: var(--color-gray-100);
    --color-tooltip-background: var(--color-gray-800);
    --color-tooltip-shadow: none;

    --stripes-bg-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 20 20'%3E%3Cg %3E%3Cpolygon fill='%23FFFFFF' points='20 10 10 0 0 0 20 20'/%3E%3Cpolygon fill='%23FFFFFF' points='0 10 0 20 10 20'/%3E%3C/g%3E%3C/svg%3E");
}

.dark {
    --color-tooltip-text: var(--color-primary-contrast);
    --color-tooltip-background: var(--color-primary-shade);
    --color-tooltip-shadow: none;
    --stripes-bg-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 20 20'%3E%3Cg %3E%3Cpolygon fill='%2320252c' points='20 10 10 0 0 0 20 20'/%3E%3Cpolygon fill='%2320252c' points='0 10 0 20 10 20'/%3E%3C/g%3E%3C/svg%3E");
}

.base-theme.blue-theme.dark {
    --color-field: #20252C;
    --color-inverse: #1A1D21; /*Cards*/
    --color-popup: rgba(26, 29, 33, 0.98); /*Sidebar*/

    --color-primary: #0085FF;
    --color-primary-shade: #1a91ff;
    --color-primary-tint: #0075e0;
    --color-primary-transparent: rgba(0, 133, 255, 0.05);
    --color-primary-contrast: var(--color-gray-900);

    /* General colors */
    --color-gray-50: #252525;
    --color-gray-100: #181818;
    --color-gray-200: #383838;
    --color-gray-300: #2f2f2f;
    --color-gray-400: #9CA3AF;
    --color-gray-500: #898989;
    --color-gray-600: #D1D5DB;
    --color-gray-700: #b8b8b8;
    --color-gray-800: #e6ebef;
    --color-gray-900: #e2e8f0;
}

.base-theme.pink-theme {
    --color-primary: #be185d;
    --color-primary-shade: #9d174d;
    --color-primary-tint: #db2777;
    --color-primary-transparent: rgba(219, 39, 119, 0.05);
    --color-primary-contrast: var(--color-gray-50);

    --color-secondary: #6BC9FF;
    --color-secondary-shade: #5eb1e0;
    --color-secondary-tint: #7aceff;
}

.base-theme.pink-theme.dark {
    --color-field: #20252C;
    --color-inverse: #1A1D21; /*Cards*/
    --color-popup: rgba(26, 29, 33, 0.98); /*Sidebar*/

    --color-primary: #df3d85;
    --color-primary-shade: #DB2777;
    --color-primary-tint: #ec4899;
    --color-primary-transparent: rgba(219, 39, 119, 0.05);
    --color-primary-contrast: var(--color-gray-900);

    --color-secondary: #6BC9FF;
    --color-secondary-shade: #5eb1e0;
    --color-secondary-tint: #7aceff;

    --color-gray-50: #1e2124;
    --color-gray-100: #222529; /*bg*/
    --color-gray-200: #40303A; /*subtle border*/
    --color-gray-300: #57534E; /*border*/
    --color-gray-400: #78716C;
    --color-gray-500: #A8A29E;
    --color-gray-600: #D6D3D1;
    --color-gray-700: #E7E5E4;
    --color-gray-800: #F5F5F4;
    --color-gray-900: #FAFAF9;  /*text*/
}

.base-theme.green-theme {
    --color-primary: #379253;
    --color-primary-shade: #308049;
    --color-primary-tint: #4b9d64;
    --color-primary-transparent: rgba(63, 166, 94, 0.05);
    --color-primary-contrast: var(--color-gray-50);

    --color-secondary: #6b21a8;
    --color-secondary-shade: #5e1d94;
    --color-secondary-tint: #7a37b1;
}

.base-theme.green-theme.dark {
    --color-field: #171a1d;
    --color-inverse: #222529; /*Cards*/
    --color-popup: rgba(26, 29, 33, 0.98); /*Sidebar*/

    --color-primary: #3FA65E;
    --color-primary-shade: #4EE077;
    --color-primary-tint: #379253;
    --color-primary-transparent: rgba(63, 166, 94, 0.05);
    --color-primary-contrast: var(--color-inverse);

    --color-secondary: #6b21a8;
    --color-secondary-shade: #5e1d94;
    --color-secondary-tint: #7a37b1;

    --color-gray-50: #1e2124;
    --color-gray-100: #1A1D21; /*bg*/
    --color-gray-200: #1F312A; /*subtle border*/
    --color-gray-300: #525252; /*border*/
    --color-gray-400: #737373;
    --color-gray-500: #A3A3A3;
    --color-gray-600: #D4D4D4;
    --color-gray-700: #E5E5E5;
    --color-gray-800: #F5F5F5;
    --color-gray-900: #D1D2D3;  /*text*/
    --stripes-bg-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 20 20'%3E%3Cg %3E%3Cpolygon fill='%232d2d2d' points='20 10 10 0 0 0 20 20'/%3E%3Cpolygon fill='%232d2d2d' points='0 10 0 20 10 20'/%3E%3C/g%3E%3C/svg%3E");
}

.base-theme.green-600-theme {
    --color-primary: #15803d;
    --color-primary-shade: #166534;
    --color-primary-tint: #16a34a;
    --color-primary-transparent: rgba(22, 163, 74, 0.05);
    --color-primary-contrast: #fff;

    --color-secondary: #6b21a8;
    --color-secondary-shade: #5e1d94;
    --color-secondary-tint: #7a37b1;
}

.base-theme.green-600-theme.dark {
    --color-field: #171a1d;
    --color-inverse: #222529; /*Cards*/
    --color-popup: rgba(26, 29, 33, 0.98); /*Sidebar*/

    --color-primary: #16a34a;
    --color-primary-shade: #2dac5c;
    --color-primary-tint: #138f41;
    --color-primary-transparent: rgba(22, 163, 74, 0.05);
    --color-primary-contrast: #000;

    --color-secondary: #6b21a8;
    --color-secondary-shade: #5e1d94;
    --color-secondary-tint: #7a37b1;

    --color-gray-50: #1e2124;
    --color-gray-100: #1A1D21; /*bg*/
    --color-gray-200: #1F312A; /*subtle border*/
    --color-gray-300: #525252; /*border*/
    --color-gray-400: #737373;
    --color-gray-500: #A3A3A3;
    --color-gray-600: #D4D4D4;
    --color-gray-700: #E5E5E5;
    --color-gray-800: #F5F5F5;
    --color-gray-900: #D1D2D3;  /*text*/
    --stripes-bg-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 20 20'%3E%3Cg %3E%3Cpolygon fill='%231a1d21' points='20 10 10 0 0 0 20 20'/%3E%3Cpolygon fill='%231a1d21' points='0 10 0 20 10 20'/%3E%3C/g%3E%3C/svg%3E");
}

.base-theme.violet-theme {
    --color-primary: #9168c3;
    --color-primary-shade: #805cac;
    --color-primary-tint: #9c77c9;
    --color-primary-transparent: rgba(118, 44, 237, 0.05);
    --color-primary-contrast: var(--color-gray-50);
}

.base-theme.violet-theme.dark {
    --color-field: #121212;
    --color-inverse: #232323; /*Cards*/
    --color-popup: #1d1d1d; /*Sidebar*/

    --color-primary: #9168c3;
    --color-primary-shade: #9c77c9;
    --color-primary-tint: #805cac;
    --color-primary-transparent: rgba(118, 44, 237, 0.05);
    --color-primary-contrast: var(--color-gray-50);

    --color-secondary: #6b21a8;
    --color-secondary-shade: #5e1d94;
    --color-secondary-tint: #7a37b1;

    --color-gray-50: #191919;
    --color-gray-100: #313131; /*bg*/
    --color-gray-200: #353535; /*subtle border*/
    --color-gray-300: #423d46; /*border*/
    --color-gray-400: #534b5e;
    --color-gray-500: #A8A29E;
    --color-gray-600: #D6D3D1;
    --color-gray-700: #E7E5E4;
    --color-gray-800: #F5F5F4;
    --color-gray-900: #FAFAF9;  /*text*/

    --color-tooltip-popover-background: #6b21a8;
    --stripes-bg-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 20 20'%3E%3Cg %3E%3Cpolygon fill='%232d2d2d' points='20 10 10 0 0 0 20 20'/%3E%3Cpolygon fill='%232d2d2d' points='0 10 0 20 10 20'/%3E%3C/g%3E%3C/svg%3E");
}

.base-theme.purple-theme {
    --color-primary: #9333EA;
    --color-primary-shade: #7e22ce;
    --color-primary-tint: #a855f7;
    --color-primary-transparent: rgba(118, 44, 237, 0.05);
    --color-primary-contrast: var(--color-gray-50);
}

.base-theme.purple-theme.dark {
    --color-field: #121212;
    --color-inverse: #232323; /*Cards*/
    --color-popup: #1d1d1d; /*Sidebar*/

    --color-primary: #9333EA;
    --color-primary-shade: #a855f7;
    --color-primary-tint: #7e22ce;
    --color-primary-transparent: rgba(118, 44, 237, 0.05);
    --color-primary-contrast: white;

    --color-secondary: #6b21a8;
    --color-secondary-shade: #5e1d94;
    --color-secondary-tint: #7a37b1;

    --color-gray-50: #191919;
    --color-gray-100: #313131; /*bg*/
    --color-gray-200: #353535; /*subtle border*/
    --color-gray-300: #423d46; /*border*/
    --color-gray-400: #534b5e;
    --color-gray-500: #A8A29E;
    --color-gray-600: #D6D3D1;
    --color-gray-700: #E7E5E4;
    --color-gray-800: #F5F5F4;
    --color-gray-900: #FAFAF9;  /*text*/

    --color-tooltip-popover-background: #6b21a8;
    --stripes-bg-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 20 20'%3E%3Cg %3E%3Cpolygon fill='%232d2d2d' points='20 10 10 0 0 0 20 20'/%3E%3Cpolygon fill='%232d2d2d' points='0 10 0 20 10 20'/%3E%3C/g%3E%3C/svg%3E");
}

.base-theme.indigo-theme {
    --color-primary: #4338ca;
    --color-primary-shade: #3730a3;
    --color-primary-tint: #4f46e5;
    --color-primary-transparent: rgba(79, 70, 229, 0.05);
    --color-primary-contrast: var(--color-gray-50);
}

.base-theme.indigo-theme.dark {
    --color-field: #1d2432;
    --color-inverse: #111827; /*Cards*/
    --color-popup: rgba(15, 22, 35, 0.98); /*Sidebar*/

    --color-primary: #6366f1;
    --color-primary-shade: #818cf8;
    --color-primary-tint: #4f46e5;
    --color-primary-transparent: rgba(79, 70, 229, 0.05);
    --color-primary-contrast: white;

    /* General colors */
    --color-gray-50: #0f1623;
    --color-gray-100: #151d2b; /*bg*/
    --color-gray-200: #1f2937; /*subtle border*/
    --color-gray-300: #343a47; /*border*/
    --color-gray-400: #9ca3af;
    --color-gray-500: #A3A3A3;
    --color-gray-600: #D4D4D4;
    --color-gray-700: #E5E5E5;
    --color-gray-800: #e5e7eb;
    --color-gray-900: #ffffff;  /*text*/
    --stripes-bg-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 20 20'%3E%3Cg %3E%3Cpolygon fill='%231d2432' points='20 10 10 0 0 0 20 20'/%3E%3Cpolygon fill='%231d2432' points='0 10 0 20 10 20'/%3E%3C/g%3E%3C/svg%3E");
}

.base-theme.amber-theme {
    --color-primary: #d97706;
    --color-primary-shade: #a16207;
    --color-primary-tint: #eab308;
    --color-primary-transparent: rgba(217, 119, 6, 0.05);
    --color-primary-contrast: var(--color-gray-50);

    --color-secondary: #6BC9FF;
    --color-secondary-shade: #5eb1e0;
    --color-secondary-tint: #7aceff;
}

.base-theme.amber-theme.dark {
    --color-field: #20252C;
    --color-inverse: #1A1D21; /*Cards*/
    --color-popup: rgba(26, 29, 33, 0.98); /*Sidebar*/

    --color-primary: #f59e0b;
    --color-primary-shade: #fbbf24;
    --color-primary-tint: #ca8a04;
    --color-primary-transparent: rgba(217, 119, 6, 0.05);
    --color-primary-contrast: var(--color-inverse);

    /* General colors */
    --color-gray-50: #252525;
    --color-gray-100: #181818;
    --color-gray-200: #383838;
    --color-gray-300: #2f2f2f;
    --color-gray-400: #9CA3AF;
    --color-gray-500: #898989;
    --color-gray-600: #D1D5DB;
    --color-gray-700: #b8b8b8;
    --color-gray-800: #e6ebef;
    --color-gray-900: #e2e8f0;
}

.base-theme.orange-theme {
    --color-primary: #ea580c;
    --color-primary-shade: #c2410c;
    --color-primary-tint: #f97316;
    --color-primary-transparent: rgba(234, 88, 12, 0.05);
    --color-primary-contrast: var(--color-gray-50);

    --color-secondary: #6BC9FF;
    --color-secondary-shade: #5eb1e0;
    --color-secondary-tint: #7aceff;
}

.base-theme.orange-theme.dark {
    --color-field: #20252C;
    --color-inverse: #1A1D21; /*Cards*/
    --color-popup: rgba(26, 29, 33, 0.98); /*Sidebar*/

    --color-primary: #f97316;
    --color-primary-shade: #fb923c;
    --color-primary-tint: #ea580c;
    --color-primary-transparent: rgba(234, 88, 12, 0.05);
    --color-primary-contrast: var(--color-inverse);

    /* General colors */
    --color-gray-50: #18181B;
    --color-gray-100: #27272A;
    --color-gray-200: #3F3F46;
    --color-gray-300: #52525B;
    --color-gray-400: #71717A;
    --color-gray-500: #A1A1AA;
    --color-gray-600: #D4D4D8;
    --color-gray-700: #E4E4E7;
    --color-gray-800: #F4F4F5;
    --color-gray-900: #FAFAFA;
}

.base-theme.adwaita-theme {
    --color-primary: #3584e4;
    --color-primary-shade: #2f74c9;
    --color-primary-tint: #4990e7;
    --color-primary-transparent: rgba(53, 132, 228, 0.05);
    --color-primary-contrast: var(--color-gray-50);

    --color-gray-50: #fafafa;
    --color-gray-100: #F3F4F6;
    --color-gray-200: #dcdcdc;
    --color-gray-300: #dcdcdc;
    --color-gray-400: #323232;
    --color-gray-500: #6B7280;
    --color-gray-600: #4B5563;
    --color-gray-700: #323232;
    --color-gray-800: #1F2937;
    --color-gray-900: #111827;
}

.base-theme.adwaita-theme.dark {
    --color-field: #404040;
    --color-inverse: #353535; /*Cards*/
    --color-popup: rgba(32, 32, 32, 0.98); /*Sidebar*/

    --color-primary: #3584e4;
    --color-primary-shade: #2f74c9;
    --color-primary-tint: #4990e7;
    --color-primary-transparent: rgba(53, 132, 228, 0.05);
    --color-primary-contrast: white;

    --color-secondary: #393939;
    --color-secondary-shade: #323232;
    --color-secondary-tint: #4d4d4d;

    --color-gray-50: #1e1e1e;
    --color-gray-100: #242424; /*bg*/
    --color-gray-200: #303030; /*subtle border*/
    --color-gray-300: #454545; /*border*/
    --color-gray-400: #ffffff;
    --color-gray-500: #d2d2d2;
    --color-gray-600: #D6D3D1;
    --color-gray-700: #E7E5E4;
    --color-gray-800: #F5F5F4;
    --color-gray-900: #ffffff;  /*text*/
    --stripes-bg-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 20 20'%3E%3Cg %3E%3Cpolygon fill='%23303030' points='20 10 10 0 0 0 20 20'/%3E%3Cpolygon fill='%23303030' points='0 10 0 20 10 20'/%3E%3C/g%3E%3C/svg%3E");
}