@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .min-w-icon {
        min-width: 1.25rem;
    }

    .form-group {
        @apply relative col-span-6 md:col-span-3 text-tm-gray-700
    }

    .form-control {
        @apply block text-tm-gray-700 w-full border border-tm-gray-300 placeholder:text-tm-gray-500 bg-field shadow-sm py-1 px-3 focus:outline-none focus:bg-field focus:ring-0 focus:border-primary sm:text-sm disabled:bg-tm-gray-50 disabled:text-tm-gray-500 focus:text-tm-gray-700 rounded-input
    }

    .table-header-filter {
        @apply block h-9 text-tm-gray-700 w-full border border-t-field border-l-field border-r-tm-gray-200 border-b-tm-gray-200 focus:border placeholder:text-tm-gray-500 bg-field shadow-sm py-1 px-1 focus:outline-none focus:bg-field focus:ring-0 focus:border-primary sm:text-sm disabled:bg-tm-gray-100 disabled:text-tm-gray-500 focus:text-tm-gray-700
    }

    .label-float {
        @apply text-tm-gray-700 font-semibold absolute -top-2.5 left-2.5 bg-inverse px-1 text-xs z-10
    }

    .checkbox {
        @apply focus:ring-primary focus:ring-offset-inverse h-4 w-4 text-primary border-tm-gray-400 rounded bg-tm-gray-100 cursor-pointer disabled:text-tm-gray-400
    }

    .radio {
        @apply focus:ring-primary focus:ring-offset-inverse h-4 w-4 text-primary border-tm-gray-200 rounded-full bg-tm-gray-100 cursor-pointer
    }

    .field-btn {
        @apply rounded-md text-tm-gray-700 px-2 py-1 relative -top-0.5 hover:bg-tm-gray-100 hover:text-tm-gray-700 focus:outline-none focus-visible:ring-2 ring-primary-tint
    }

    .form-error {
        @apply ring-red-300 ring-4
    }

    .btn {
        @apply h-btn relative inline-flex items-center text-sm font-semibold leading-5 box-border
    }

    .btn-text {
        @apply py-2 hover:text-primary text-primary-shade font-medium border border-transparent disabled:cursor-default disabled:text-tm-gray-700 disabled:opacity-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-tint rounded-btn
    }

    .btn-primary {
        @apply px-5 py-2 rounded-btn text-primary-contrast bg-primary hover:text-primary-contrast hover:bg-primary-shade focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-inverse focus:ring-primary shadow-sm cursor-pointer disabled:cursor-default disabled:border-tm-gray-200 disabled:bg-tm-gray-200 disabled:text-tm-gray-700
    }

    .btn-secondary {
        @apply px-5 py-2 rounded-btn text-white border-secondary bg-secondary hover:bg-secondary-tint hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-inverse focus:ring-primary shadow-sm cursor-pointer disabled:cursor-default disabled:border-tm-gray-700 disabled:bg-tm-gray-700 disabled:opacity-75 disabled:text-inverse
    }

    .btn-outline {
        @apply px-5 py-2 border-primary rounded-btn text-primary bg-transparent border cursor-pointer hover:text-tm-gray-700 hover:border-tm-gray-900 hover:bg-transparent focus:outline-none focus-visible:bg-primary-tint focus-visible:text-inverse disabled:cursor-default disabled:border-tm-gray-700 disabled:text-tm-gray-700 disabled:hover:bg-transparent disabled:opacity-75
    }

    .btn-outline-secondary {
        @apply px-5 py-2 border-tm-gray-300 rounded-btn text-tm-gray-700 bg-transparent border shadow-sm cursor-pointer hover:text-tm-gray-700 hover:border-tm-gray-700 hover:bg-transparent focus:outline-none focus:border-transparent focus:ring-2 focus:ring-primary disabled:cursor-default disabled:border-tm-gray-200 disabled:text-tm-gray-700 disabled:hover:bg-transparent disabled:opacity-75
    }

    .btn-danger {
        @apply px-5 py-2 rounded-btn text-white bg-red-600 hover:bg-red-700 outline-none focus:ring-2 focus:ring-red-400 cursor-pointer disabled:cursor-default disabled:border-tm-gray-700 disabled:text-inverse disabled:bg-tm-gray-700 disabled:hover:bg-tm-gray-700 disabled:opacity-75
    }

    .btn-warning {
        @apply px-5 py-2 rounded-btn text-white bg-yellow-600 hover:bg-yellow-700 outline-none focus:ring-2 focus:ring-yellow-400 cursor-pointer disabled:cursor-default disabled:border-tm-gray-700 disabled:text-inverse disabled:bg-tm-gray-700 disabled:hover:bg-tm-gray-700 disabled:opacity-75
    }

    .btn-danger-outline {
        @apply px-5 py-2 border border-red-600 rounded-btn text-red-600 hover:text-tm-gray-700 hover:border-tm-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:cursor-default disabled:border-tm-gray-700 disabled:text-tm-gray-700 disabled:hover:bg-transparent
    }

    .btn-close {
        @apply text-tm-gray-700 rounded-full focus:outline-none p-1 w-6 h-6 bg-tm-gray-300 hover:bg-tm-gray-200 hover:text-tm-gray-600 focus:ring-primary-tint focus:ring-2
    }

    .btn-icon {
        @apply p-2 rounded-btn text-tm-gray-400 hover:bg-tm-gray-200 hover:text-tm-gray-600 focus:outline-none focus-visible:ring-2 ring-primary-tint disabled:text-tm-gray-300 disabled:hover:bg-transparent disabled:hover:text-tm-gray-300
    }

    .btn-form-right {
        @apply border secondary-dark border-l-0 px-2 flex items-center hover:bg-tm-gray-100 h-full
    }

    .btn-form-left {
        @apply border secondary-dark border-r-0 px-2 flex items-center hover:bg-tm-gray-100 h-full
    }

    .btn-header {
        @apply bg-inverse w-9 h-9 p-0 justify-center rounded-btn border border-tm-gray-300 text-tm-gray-600 hover:text-tm-gray-700 hover:border-tm-gray-700 disabled:hover:border-tm-gray-300 disabled:bg-tm-gray-300 disabled:text-tm-gray-100
    }

    .btn-table-action {
        @apply p-1.5 mx-1.5 rounded-btn text-tm-gray-600 hover:bg-tm-gray-200 hover:text-tm-gray-700 disabled:hover:bg-transparent focus:outline-none focus:ring-2 ring-primary-tint
    }

    .notification-button {
        @apply flex w-full items-center justify-center rounded-none rounded-r-lg border border-transparent p-4 text-sm font-medium text-primary hover:text-primary-tint focus:outline-none focus:ring-2 focus:ring-primary-tint flex-1
    }

    .icon-btn {
        @apply p-1 rounded-btn text-tm-gray-700 hover:text-tm-gray-700 hover:bg-tm-gray-100 focus:outline-none focus-visible:ring-2 focus:ring-offset-2 focus:ring-primary-tint ring-offset-inverse disabled:text-tm-gray-500 disabled:hover:bg-transparent
    }

    .btn-field {
        @apply animate-button-fade-in w-5 h-5 text-primary hover:text-primary
    }

    .btn-input {
        @apply justify-center w-9 h-9 flex-shrink-0 ml-1 rounded-btn p-2 text-primary bg-field focus:outline-none focus-visible:ring-2 focus:ring-offset-2 focus:ring-primary-tint ring-offset-inverse hover:bg-tm-gray-200 hover:text-primary border border-tm-gray-300 shadow-sm disabled:bg-tm-gray-200 disabled:text-tm-gray-500
    }

    .dropdown-item {
        @apply px-3 py-2 text-sm hover:bg-primary hover:text-primary-contrast focus:bg-primary focus:text-primary-contrast w-full text-left
    }

    .text-area {
        @apply w-full form-control
    }

    .cell {
        @apply p-3
    }

    .app-body-height {
        @apply h-[calc(100vh-64px)]
    }

    .app-body-height-footer {
        @apply h-[calc(100vh-128px)]
    }

    .inset-center {
        @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
    }

    .h-dialog-body {
        @apply max-h-[calc(100vh-12rem)] overflow-auto
    }

    .table-th-icon {
        @apply group-hover:text-gray-50
    }

    .table-th-column-sorted {
        @apply text-primary
    }

    .table-th-column-not-sorted {
        @apply text-tm-gray-700 opacity-30
    }

    .table-td-padding {
        @apply py-3.5 pl-4 pr-3 sm:pl-6
    }

    .table-th-padding {
        @apply py-3.5 pl-4 pr-3 sm:pl-6
    }

    .table-th {
        @apply text-sm font-semibold text-tm-gray-700 capitalize
    }

    .label-stack {
        @apply flex items-center text-sm font-semibold text-tm-gray-900 h-5 whitespace-nowrap
    }

    .mention {
        @apply bg-primary-transparent text-primary py-0.5 px-2 inline-block rounded-md font-bold
    }

    .popover-card {
        @apply bg-popup border border-tm-gray-300 rounded-md
    }
}

/* Override */
.select-css-search input:focus {
    box-shadow: none;
}

body {
    font-family: var(--font-body);
}

/* Theme classes */

.label-bg { /* Background for floating labels */
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, var(--color-inverse) 50%);
}

.label-disabled-bg {
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(243, 244, 246, 0) 50%, rgba(243, 244, 246, 1) 50%);
}

.bg-stripes {
    background-color: #F9FAFB;
    background-image: var(--stripes-bg-image);
}

.form-control:disabled,
.table-header-filter:disabled {
    background-image: var(--stripes-bg-image);
}

/* Dark theme */
.dark .shadow-header {
    box-shadow: 0 0 0 1px var(--color-gray-200);
}

.dark .label {
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, var(--color-field) 50%);
}

.dark .label-disabled-bg {
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(75, 75, 75, 0) 55%, var(--color-gray-200) 45%);
}

.dark .bg-stripes {
    background-color: var(--color-gray-100);
    background-image: var(--stripes-bg-image);
}

::-moz-selection { /* Code for Firefox */
    color: var(--color-primary-contrast);
    background: var(--color-primary);
}

::selection {
    color: var(--color-primary-contrast);
    background: var(--color-primary);
}

/* Chrome scrollbar */
::-webkit-scrollbar {
    height: .75rem;
    width: .75rem;
    background: var(--color-gray-50);
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 0.5rem;
    background: var(--color-gray-400);
}

::-webkit-scrollbar-thumb:hover {
    background: var(--color-gray-500);
}

::-webkit-scrollbar-corner {
    background: var(--color-gray-200);
}

.dark ::-webkit-scrollbar-thumb:hover {
    background: var(--color-gray-600);
}

/* Date picker styling */
.react-datepicker {
    border-radius: var(--rounded-card);
    border-color: var(--color-gray-300);
    background: var(--color-popup);
}

.react-datepicker__month-container {
    background: transparent;
    color: var(--color-gray-900);
}

.react-datepicker__month {
    border-bottom-left-radius: var(--rounded-card);
    border-bottom-right-radius: var(--rounded-card);
    margin: 0;
    padding: 0.25rem;
    background: var(--color-gray-50);
}

.react-datepicker__triangle {
    display: none;
}

.react-datepicker__day-name {
    color: var(--color-gray-900);
}

.react-datepicker__header {
    background: var(--color-inverse);
    border-color: var(--color-gray-300);
    border-top-left-radius: var(--rounded-card);
    border-top-right-radius: var(--rounded-card);
}

.react-datepicker__current-month,
.react-datepicker__day {
    color: var(--color-gray-900);
}

.react-datepicker__day--selected {
    background: var(--color-primary);
    color: var(--color-primary-contrast);
}

.react-datepicker__day--keyboard-selected {
    background: var(--color-primary-tint);
    color: var(--color-primary-contrast);
}

.react-datepicker__day:hover {
    background: var(--color-primary-shade);
    color: var(--color-primary-contrast);
}

.react-datepicker__month-dropdown,
.react-datepicker__year-dropdown {
    background: var(--color-popup);
    border-color: var(--color-gray-300)
}

.react-datepicker__year-option:hover, .react-datepicker__month-option:hover, .react-datepicker__month-year-option:hover {
    background: var(--color-primary);
}

.react-datepicker__navigation--next {
    border-left-color: var(--color-primary-shade);
}

.react-datepicker__navigation--next:hover {
    border-left-color: var(--color-primary-tint);
}

.react-datepicker__navigation--previous {
    border-right-color: var(--color-primary-shade);
}

.react-datepicker__navigation--previous:hover {
    border-right-color: var(--color-primary-tint);
}

.react-datepicker__year-read-view--down-arrow {
    border-top-color: var(--color-primary-shade);
}

.react-datepicker__year-read-view--down-arrow:hover {
    border-top-color: var(--color-primary-tint);
}

.react-datepicker__month-read-view--down-arrow {
    border-top-color: var(--color-primary-shade);
}

.react-datepicker__month-read-view--down-arrow:hover {
    border-top-color: var(--color-primary-tint);
}

.react-datepicker__day--disabled {
    background: var(--color-gray-300);
    color: var(--color-gray-400);
    border-radius: var(--rounded-card);
}

.react-datepicker__day--disabled:hover {
    background: var(--color-gray-300);
    cursor: default;
}

/* Google autocomplete */
.pac-container {
    z-index: 1051 !important;
    background: var(--color-popup);
    border: 1px solid var(--color-gray-200);
    border-radius: var(--rounded-card);
}

.pac-item {
    color: var(--color-gray-700);
    border-top: 1px solid var(--color-gray-200);
}

.pac-item-query {
    color: var(--color-gray-900);
}

.pac-item:hover {
    background-color: var(--color-primary-transparent);
    cursor: pointer;
}

/* Trimble maps */
.trimblemaps-ctrl-walegend {
    background-color: var(--color-inverse);
    color: var(--color-gray-900);
    border-radius: var(--rounded-card);
}

/* Custom classes */
.resource-table-height {
    --max-resource-table-height-primary-modifier: 22;
    --max-resource-table-height-secondary-modifier: 0;
    --max-resource-table-height-total-modifier: calc(var(--max-resource-table-height-primary-modifier) + var(--max-resource-table-height-secondary-modifier));
    --max-resource-table-height-total-modifier-rem: calc(var(--max-resource-table-height-total-modifier) * 1rem);
    max-height: calc(100vh - var(--max-resource-table-height-total-modifier-rem))
}

.resource-table-height--with-stats {
    --max-resource-table-height-primary-modifier: 28;
}

.resource-table-height--full-screen {
    --max-resource-table-height-primary-modifier: 10;
}

.resource-table-height--with-bottom-chat {
    --max-resource-table-height-secondary-modifier: 22.5;
}

.footer--with-bottom-chat {
    --max-resource-table-height-secondary-modifier: 24;
    bottom: calc(var(--max-resource-table-height-secondary-modifier) * 1rem);
}

.pdf-canvas canvas {
    max-width: 100%;
}

.react-datepicker-popper {
    z-index: 11;
}

.shadow-header {
    box-shadow: 0 1px 15px rgba(0, 0, 0, .04), 0 1px 6px rgba(0, 0, 0, .04);
}

.shadow-menu {
    box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1), 0 3px 20px rgba(0, 0, 0, 0.1);
}

.shadow-card {
    box-shadow: 0 1px 15px rgba(0, 0, 0, .04), 0 1px 6px rgba(0, 0, 0, .04);
}

.app-menu-width {
    width: 13rem;
    min-width: 13rem;
}

.fs-display-show {
    display: none;
}

@media (min-width: 768px) {
    .page-footer-width {
        width: calc(100% - 13rem);
    }
}

@media all and (display-mode: fullscreen) {
    .fs-display-show {
        display: block;
    }

    .fs-display-hide {
        display: none;
    }
}

.react-datepicker-wrapper {
    width: 100% !important;
}

.image-auth {
    background: url("../../assets/images/login/truck.jpg") #426eab no-repeat center center;
}

.simple-sticky-table {
    overflow: auto;
}

.simple-sticky-table tr,
.simple-sticky-table td,
.simple-sticky-table th {
    vertical-align: middle;
    border-color: var(--color-gray-200) !important;
}

.simple-sticky-table table {
    border-collapse: separate;
    border-spacing: 0;
    white-space: nowrap;
    border-color: var(--color-gray-200);
}

.simple-sticky-table table tbody td:first-child,
.simple-sticky-table table thead th:first-child,
.simple-sticky-table table tfoot td:first-child {
    padding-left: 1.5em;
}

.simple-sticky-table table tbody td:first-child {
    position: relative;
}

.simple-sticky-table table thead th:first-child,
.simple-sticky-table table.with-bulk-select thead th:nth-child(2) {
    position: sticky;
    left: 0;
    z-index: 2;
    background: var(--color-inverse);
}

/*.simple-sticky-table:not(.no-actions) table thead th:last-child{*/
/*    position: sticky;*/
/*    right: 0;*/
/*    z-index: 1;*/
/*    background: var(--color-inverse);*/
/*}*/

.simple-sticky-table table tbody td:first-child,
.simple-sticky-table table.with-bulk-select tbody td:first-child + td {
    position: sticky;
    left: 0;
    background: var(--color-inverse);
    z-index: 1;
}

.simple-sticky-table table tbody .bg-stripes td:first-child,
.simple-sticky-table table.with-bulk-select tbody .bg-stripes td:first-child + td {
    background-color: #F3F4F6;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 20 20'%3E%3Cg %3E%3Cpolygon fill='%23FFFFFF' points='20 10 10 0 0 0 20 20'/%3E%3Cpolygon fill='%23FFFFFF' points='0 10 0 20 10 20'/%3E%3C/g%3E%3C/svg%3E");
}

.simple-sticky-table table.with-bulk-select tbody td:first-child + td {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.table-is-scrolled:not(.with-bulk-select) th:first-child {
    border-right: 1px dashed var(--color-gray-200) !important;
}

/* If there is a bulk selection make first two columns sticky */
.simple-sticky-table table.with-bulk-select thead th:first-child {
    min-width: 50px;
    width: 50px;
}

.simple-sticky-table table.with-bulk-select tbody td:first-child {
    min-width: 50px;
    width: 50px;
}

.simple-sticky-table table.with-bulk-select thead th:nth-child(2),
.simple-sticky-table table.with-bulk-select tbody td:first-child + td {
    left: 50px
}

table.table-is-scrolled:not(.with-bulk-select) thead th:first-child,
table.table-is-scrolled:not(.with-bulk-select) tbody td:first-child,
table.table-is-scrolled:not(.with-bulk-select) tfoot td:first-child {
    border-right: 1px dashed var(--color-gray-200) !important;
}

table.with-bulk-select.table-is-scrolled thead th:nth-child(2),
table.with-bulk-select.table-is-scrolled tbody td:first-child + td {
    border-right: 1px dashed var(--color-gray-200) !important;
}


body {
    padding: 0;
}

h1 {
    padding: 0;
}

h5 {
    margin-bottom: 0;
}

main {
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-top: 0 !important;
}

a {
    transition: none;
}

.form-control {
    transition: none;
}

.btn.disabled, .btn:disabled {
    opacity: 0.70;
}

label {
    margin-bottom: 0;
}

.table th, .table td {
    padding-top: 0;
    padding-bottom: 0;
}

.bg-inherit {
    background: inherit;
}

.dark .has-float-label label::after,
.dark .has-float-label > span::after,
.dark .has-float-label > span::after
{
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, rgba(35, 34, 35, 1) 50%)
}

.dark .has-float-label > span{
    color: var(--color-gray-700)
}

/* Forms - refactor and remove these rules */
.form-control {
    min-height: 2.25rem;
    line-height: 1.2;
    height: auto;
}

body {
    font-family: "Nunito", sans-serif;
    font-size: 0.8rem;
    font-weight: 400;
    color: var(--color-gray-900);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px var(--color-field) inset !important;
    -webkit-text-fill-color: var(--color-gray-900) !important;
}

.link-color a {
    color: var(--color-primary);
}

.link-color a:hover {
    color: var(--color-primary-tint);
}

.no-spin::-webkit-inner-spin-button,
.no-spin::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.no-spin {
    -moz-appearance: textfield; /* Firefox */
}

.rich-text-min-height {
    min-height: 6rem!important;
}

.scrollbar-hidden::-webkit-scrollbar {
    display: none;
}

.scrollbar-hidden {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
}